import React, { useCallback, useMemo } from 'react';
import MenuList from 'src/components/atoms/menu_list';
import MenuItem from 'src/components/atoms/menu_item';
import Divider from 'src/components/atoms/divider';
import { useNavigate } from 'react-router-dom';
import { PagePath } from 'src/commons/const/page';
import { useUserInfo } from 'src/commons/state/user';
import { getYear, getMonth } from 'src/commons/const/utils/time';

type Props = {
  anchorEl: Element | null;
  onClose: VoidFunction;
  onLogout: VoidFunction;
};

const AccountContent: React.FC<Props> = ({ anchorEl, onClose, onLogout }) => {
  const navigate = useNavigate();
  const { info } = useUserInfo();

  const handleClickMyAccount = useCallback(() => {
    onClose();
    navigate(PagePath.UserDetail.replace(':id', String(info?.id)));
  }, [navigate, onClose, info?.id]);

  const handleClickTimecard = useCallback(() => {
    onClose();
    const year = getYear();
    const month = getMonth();
    navigate(
      PagePath.Timecard.replace(':id', String(info?.id))
        .replace(':year', year)
        .replace(':month', month),
    );
  }, [navigate, onClose, info?.id]);

  const handleClickLogin = useCallback(() => {
    onClose();
    navigate(PagePath.Login);
  }, [navigate, onClose]);

  const handleClickLogout = useCallback(() => {
    onClose();
    onLogout();
  }, [onLogout, onClose]);

  return useMemo(
    () => (
      <MenuList anchorEl={anchorEl} onClose={onClose}>
        {info
          ? [
              <MenuItem
                onClick={handleClickMyAccount}
                key="menu-list-my-account"
              >
                My Account
              </MenuItem>,
              <MenuItem onClick={handleClickTimecard} key="menu-list-timecard">
                Timecard
              </MenuItem>,
              <Divider key="menu-list-divider" />,
              <MenuItem onClick={handleClickLogout} key="menu-list-logout">
                Logout
              </MenuItem>,
            ]
          : [
              <MenuItem onClick={handleClickLogin} key="menu-list-login">
                Login
              </MenuItem>,
            ]}
      </MenuList>
    ),
    [
      anchorEl,
      onClose,
      handleClickMyAccount,
      handleClickTimecard,
      info,
      handleClickLogout,
      handleClickLogin,
    ],
  );
};

export default AccountContent;
