import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container, Grid, Stack, TextField } from '@mui/material';
import PageTitle from 'src/components/atoms/page_title';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { PagePath } from 'src/commons/const/page';
import { User } from 'src/commons/types/user.js';
import { StatusCode } from 'src/commons/types/rest';
import { getUserDetail, postUserUpdate } from 'src/commons/rest/user';
import Axios from 'axios';
import { ErrorStatusMessage } from 'src/commons/const/rest';
import ErrorMessage from 'src/components/atoms/error_message';
import { FormInput } from 'src/commons/types/form_input.js';
import { RoleType } from 'src/commons/types/role_type.js';
import { createStyles, makeStyles } from '@mui/styles';
import { userHasAccessToken, useUserInfo } from 'src/commons/state/user';
import { RoleTypeAdmin } from 'src/commons/const/role';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      padding: '16.5px 14px',
      fontSize: '16px',
      borderColor: '#C6C6C6',
      borderRadius: '4px',
      border: '1px solid',
    },
  }),
);

const UsersDetailEditPage: React.FC = () => {
  const { info } = useUserInfo();
  const roleType = info?.role__type;
  const hasAccessToken = useRecoilValue(userHasAccessToken);

  const schema = yup.object({
    last_name: yup.string().required('必須項目です。'),
    first_name: yup.string().required('必須項目です。'),
    last_name_kana: yup.string().required('必須項目です。'),
    first_name_kana: yup.string().required('必須項目です。'),
    address: yup.string().required('必須項目です。'),
    tel_number: yup.string().required('必須項目です。'),
    slack_id: yup.string().required('必須項目です。'),
    employee_id:
      roleType === RoleTypeAdmin
        ? yup.string().required('必須項目です。')
        : yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
  });

  const classes = useStyles();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<StatusCode | undefined>(
    undefined,
  );
  const params = useParams();
  const userId: string | undefined = useMemo(() => params.id, [params.id]);

  // slackで初めてコマンドを打ったとき、クエリのslackIdを取得しデフォルト値に入力
  const location = useLocation();
  const slackId: string | undefined = useMemo(
    () => location.state as string,
    [location.state],
  );

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const { roleTypeSelect, ...rest } = data;
    const roleData = { role_type: Number(roleTypeSelect) as RoleType };

    try {
      await postUserUpdate(rest, String(user!.id));
      // ロールの変更
      await postUserUpdate(roleData, String(user!.id));
      navigate(PagePath.UserDetail.replace(':id', userId!));
    } catch (error) {
      setIsError(true);
      const status = Axios.isAxiosError(error)
        ? error?.response?.status
        : undefined;
      const foundStatus = (
        Object.keys(ErrorStatusMessage) as (keyof typeof ErrorStatusMessage)[]
      ).find((key) => key === `${status}`);
      setErrorStatus(foundStatus);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (hasAccessToken && userId) {
          const userDetail = await getUserDetail(userId);
          setUser(userDetail);
          setIsError(false);
        }
      } catch (error) {
        setIsError(true);
        const status = Axios.isAxiosError(error)
          ? error?.response?.status
          : undefined;
        const foundStatus = (
          Object.keys(ErrorStatusMessage) as (keyof typeof ErrorStatusMessage)[]
        ).find((key) => key === `${status}`);
        setErrorStatus(foundStatus);
      }
    })();
  }, [userId, hasAccessToken]);

  const errorContent = useMemo(() => {
    if (errorStatus && errorStatus in ErrorStatusMessage) {
      return ErrorStatusMessage[errorStatus];
    }
    return 'エラーが発生しました。時間をおいて再度試してください。';
  }, [errorStatus]);

  return (
    <>
      {!isError && user && (
        <>
          <Container maxWidth="sm" sx={{ pt: 1, pb: 5 }}>
            <PageTitle text="プロフィール編集" />
            <Grid container rowSpacing={2} sx={{ pb: 3 }}>
              <Grid item xs={6}>
                <TextField
                  required
                  id="last_name"
                  label="姓"
                  defaultValue={user?.last_name}
                  {...register('last_name')}
                  error={'last_name' in errors}
                  helperText={errors.last_name?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  id="first_name"
                  label="名"
                  defaultValue={user?.first_name}
                  {...register('first_name')}
                  error={'first_name' in errors}
                  helperText={errors.first_name?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  id="last_name_kana"
                  label="せい"
                  defaultValue={user?.last_name_kana}
                  {...register('last_name_kana')}
                  error={'last_name_kana' in errors}
                  helperText={errors.last_name_kana?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  id="first_name_kana"
                  label="めい"
                  defaultValue={user?.first_name_kana}
                  {...register('first_name_kana')}
                  error={'first_name_kana' in errors}
                  helperText={errors.first_name_kana?.message}
                />
              </Grid>
            </Grid>
            <Stack component="form" noValidate spacing={3}>
              <TextField
                required
                id="birthday"
                type="date"
                label="生年月日"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={user?.birthday}
                {...register('birthday')}
                error={'birthday' in errors}
                helperText={errors.birthday?.message}
              />

              <TextField
                required
                id="address"
                label="住所"
                defaultValue={user?.address}
                {...register('address')}
                error={'address' in errors}
                helperText={errors.address?.message}
              />

              <TextField
                required
                id="tel_number"
                label="電話番号"
                defaultValue={user?.tel_number}
                {...register('tel_number')}
                error={'tel_number' in errors}
                helperText={
                  errors.tel_number?.message
                    ? `${errors.tel_number?.message}ハイフン無しで数字のみ入力してください。 `
                    : 'ハイフン無しで数字のみ入力してください。'
                }
              />

              <TextField
                required
                id="slack_id"
                label="SlackのID"
                defaultValue={slackId || user?.slack_id}
                {...register('slack_id')}
                error={'slack_id' in errors}
                helperText={errors.slack_id?.message}
              />

              <TextField
                required
                id="join_date"
                type="date"
                defaultValue={user?.join_date}
                label="入社日"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register('join_date')}
                error={'join_date' in errors}
                helperText={errors.join_date?.message}
              />

              {roleType === RoleTypeAdmin && (
                <>
                  <select
                    className={classes.select}
                    defaultValue={user?.role__type}
                    {...register('roleTypeSelect')}
                  >
                    <option value={1}>管理者</option>
                    <option value={2}>閲覧のみ</option>
                    <option value={3}>社員</option>
                  </select>
                  <TextField
                    required
                    id="employee_id"
                    label="被保険者整理番号"
                    defaultValue={user?.employee_id}
                    {...register('employee_id')}
                    error={'employee_id' in errors}
                    helperText={errors.employee_id?.message}
                  />
                </>
              )}

              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                変更を保存
              </Button>
            </Stack>
          </Container>
        </>
      )}
      {isError && <ErrorMessage>{errorContent}</ErrorMessage>}
    </>
  );
};

export default UsersDetailEditPage;
