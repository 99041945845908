import { authApi } from 'src/commons/rest';
import { UserUpdateInput } from 'src/commons/types/user_update_input.js';
import { RoleUpdateInput } from 'src/commons/types/role_update_input.js';

// ユーザー一覧を取得する
export const getUsers = async () => {
  const { data } = await authApi.get('/users');
  return data;
};

// ユーザー詳細を取得する
export const getUserDetail = async (userId: string) => {
  const { data } = await authApi.get(`/users/${userId}`);
  return data;
};

// 自分の情報を取得する
export const getMyDetail = async () => {
  const { data } = await authApi.get('/users/me');
  return data;
};

// ユーザー詳細を更新する
export const postUserUpdate = async (
  input: UserUpdateInput | RoleUpdateInput,
  userId: string,
) => {
  await authApi.post(`/users/${userId}/update`, input);
};

// ユーザーを削除する
export const deleteUser = async (userId: string) => {
  await authApi.delete(`/users/${userId}/delete`);
};

const RestUser = {
  getUsers,
  getUserDetail,
  getMyDetail,
  postUserUpdate,
  deleteUser,
};

export default RestUser;
