export const PagePath = {
  Login: '/login',
  Home: '/',
  Users: '/users',
  UserDetail: '/users/:id',
  UserDetailEdit: '/users/:id/edit',
  NotFound: '/not-found',
  Timecard: '/timecard/:id/:year/:month',
  TimecardPreview: '/timecard/:id/:year/:month/preview',
  CsvExport: '/csv-export',
};

const Page = {
  PagePath,
};

export default Page;
