import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import TimecardTable from 'src/components/molecules/timecard_table';
import TimecardHeader from 'src/components/molecules/timecard_header';
import { Timecard } from 'src/commons/types/timecard';
import Axios from 'axios';
import { getTimecard } from 'src/commons/rest/timecard';
import { ErrorStatusMessage } from 'src/commons/const/rest';
import { StatusCode } from 'src/commons/types/rest';
import ErrorMessage from 'src/components/atoms/error_message';
import { getUserDetail } from 'src/commons/rest/user';
import { User } from 'src/commons/types/user';
import { useRecoilValue } from 'recoil';
import { userHasAccessToken } from 'src/commons/state/user';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

const TimecardFormat: React.FC = () => {
  const classes = useStyles();
  const params = useParams();
  const userId: string | undefined = useMemo(() => params.id, [params.id]);
  const year: number = useMemo(() => Number(params.year), [params.year]);
  const month: number = useMemo(() => Number(params.month), [params.month]);
  const hasAccessToken = useRecoilValue(userHasAccessToken);
  const [timecardRows, setTimecardRows] = useState<Timecard[] | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<StatusCode | undefined>(
    undefined,
  );

  useEffect(() => {
    (async () => {
      try {
        if (hasAccessToken && userId) {
          const timecard = await getTimecard(userId, year, month);
          const userDetail = await getUserDetail(userId);
          setTimecardRows(timecard.message);
          setUser(userDetail);
          setIsError(false);
        }
      } catch (error) {
        setIsError(true);
        const status = Axios.isAxiosError(error)
          ? error?.response?.status
          : undefined;
        const foundStatus = (
          Object.keys(ErrorStatusMessage) as (keyof typeof ErrorStatusMessage)[]
        ).find((key) => key === `${status}`);
        setErrorStatus(foundStatus);
      }
    })();
  }, [userId, year, month, hasAccessToken]);

  const errorContent = useMemo(() => {
    if (errorStatus && errorStatus in ErrorStatusMessage) {
      return ErrorStatusMessage[errorStatus];
    }
    return 'エラーが発生しました。時間をおいて再度試してください。';
  }, [errorStatus]);

  const fullName = useMemo(
    () => `${user?.last_name || ''} ${user?.first_name || ''}`,
    [user],
  );

  const yearMonth = useMemo(
    () => `${String(year)}年${String(month)}月`,
    [year, month],
  );

  return (
    <>
      {!isError && timecardRows && (
        <>
          <TimecardHeader yearMonth={yearMonth} fullName={fullName} />
          <div className={classes.container}>
            <TimecardTable timecardRows={timecardRows!} />
          </div>
        </>
      )}
      {isError && (
        <>
          <ErrorMessage>{errorContent}</ErrorMessage>
        </>
      )}
    </>
  );
};

export default TimecardFormat;
