import React, { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useUserInfo, userHasAccessToken } from 'src/commons/state/user';
import { getMyDetail } from 'src/commons/rest/user';
import { useSetRecoilState } from 'recoil';

const AppProvider: React.FC = ({ children }) => {
  const { setInfo } = useUserInfo();
  const setHasAccessToken = useSetRecoilState(userHasAccessToken);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      setHasAccessToken(!!user);
      if (user) {
        (async () => {
          const myDetail = await getMyDetail();
          setInfo(myDetail);
        })();
      } else {
        setInfo(null);
      }
    });
  }, [setInfo, setHasAccessToken]);

  return <>{children}</>;
};

export default AppProvider;
