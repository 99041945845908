import React, { MouseEvent, useMemo } from 'react';
import MuiIconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButtonColor } from 'src/commons/const/color';

type Props = {
  onClick?: (event: MouseEvent) => void;
  disabled?: boolean;
};

const DeleteButton: React.FC<Props> = ({ onClick, disabled }) =>
  useMemo(
    () => (
      <MuiIconButton
        size="small"
        onClick={onClick}
        disabled={disabled}
        color={IconButtonColor}
      >
        <DeleteIcon />
      </MuiIconButton>
    ),
    [onClick, disabled],
  );

export default DeleteButton;
