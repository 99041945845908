import React, { useMemo } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import DrawerBox from 'src/components/atoms/drawer_box';
import ListContent from 'src/components/atoms/list_content';
import ListLink from 'src/components/atoms/list_link';
import ListItemText from 'src/components/atoms/list_item_text';
import { PagePath } from 'src/commons/const/page';

type Props = {
  open: boolean;
  onClose: () => void;
};

const DrawerContent: React.FC<Props> = ({ open, onClose }) =>
  useMemo(
    () => (
      <MuiDrawer anchor="left" open={open} onClose={onClose}>
        <DrawerBox onClose={onClose}>
          <ListContent>
            <ListLink to={PagePath.Users}>
              <ListItemText>ユーザー一覧</ListItemText>
            </ListLink>
            <ListLink to={PagePath.CsvExport}>
              <ListItemText>勤怠情報CSV出力</ListItemText>
            </ListLink>
          </ListContent>
        </DrawerBox>
      </MuiDrawer>
    ),
    [open, onClose],
  );

export default DrawerContent;
