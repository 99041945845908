import React, { useMemo } from 'react';
import MuiBox from '@mui/material/Box';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: 250,
      maxWidth: '80vw',
    },
  }),
);

type Props = {
  onClose?: VoidFunction;
};

const DrawerBox: React.FC<Props> = ({ onClose, children }) => {
  const classes = useStyles();

  return useMemo(
    () => (
      <MuiBox
        className={classes.container}
        onKeyDown={onClose}
        onClick={onClose}
      >
        {children}
      </MuiBox>
    ),
    [classes, children, onClose],
  );
};

export default DrawerBox;
