export const consoleDebug = (debug: unknown) => {
  // eslint-disable-next-line no-console
  process.env.NODE_ENV !== 'production' && console.debug(debug);
};

export const consoleLog = (log: unknown) => {
  // eslint-disable-next-line no-console
  process.env.NODE_ENV !== 'production' && console.log(log);
};

export const consoleError = (error: unknown) => {
  // eslint-disable-next-line no-console
  process.env.NODE_ENV !== 'production' && console.error(error);
};

const Console = {
  consoleDebug,
  consoleLog,
  consoleError,
};

export default Console;
