import React, { useMemo, MouseEvent } from 'react';
import MuiMenuItem from '@mui/material/MenuItem';

type Props = {
  onClick?: (event: MouseEvent) => void;
};

const MenuItem: React.FC<Props> = ({ onClick, children }) =>
  useMemo(
    () => <MuiMenuItem onClick={onClick}>{children}</MuiMenuItem>,
    [onClick, children],
  );

export default MenuItem;
