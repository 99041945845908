import React, { useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getUsers } from 'src/commons/rest/user';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { createStyles, makeStyles } from '@mui/styles';
import { Container, Paper } from '@mui/material';
import PageTitle from 'src/components/atoms/page_title';
import ErrorMessage from 'src/components/atoms/error_message';
import { StatusCode } from 'src/commons/types/rest';
import { ErrorStatusMessage } from 'src/commons/const/rest';
import DetailButton from 'src/components/atoms/detail_button';
import TimecardButton from 'src/components/atoms/timecard_button';
import DeleteDialog from 'src/components/molecules/delete_dialog';
import { PagePath } from 'src/commons/const/page';
import { getMonth, getYear } from 'src/commons/const/utils/time';
import { userHasAccessToken, useUserInfo } from 'src/commons/state/user';
import { RoleTypeAdmin } from 'src/commons/const/role';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      height: '70vh',
      width: '100%',
    },
    iconButton: {
      color: '#1976d2',
    },
  }),
);

type Users = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  join_date: Date;
};

const UsersPage: React.FC = () => {
  const classes = useStyles();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<StatusCode | undefined>(
    undefined,
  );
  const [dataGridRows, setDataGridRows] = useState<Users[]>([]);
  const navigate = useNavigate();
  const { info } = useUserInfo();
  const roleType = info?.role__type;
  const hasAccessToken = useRecoilValue(userHasAccessToken);

  useEffect(() => {
    (async () => {
      try {
        if (hasAccessToken) {
          const users: Users[] = await getUsers();
          const result = users.map((user) => ({
            id: user.id,
            last_name: user.last_name,
            first_name: user.first_name,
            email: user.email,
            join_date: user.join_date,
          }));
          setDataGridRows(result);
          setIsError(false);
        }
      } catch (error) {
        setIsError(true);
        const status = Axios.isAxiosError(error)
          ? error?.response?.status
          : undefined;
        const foundStatus = (
          Object.keys(ErrorStatusMessage) as (keyof typeof ErrorStatusMessage)[]
        ).find((key) => key === `${status}`);
        setErrorStatus(foundStatus);
      }
    })();
  }, [hasAccessToken]);

  const getFullName = (params: GridValueGetterParams) =>
    `${params.row.last_name || ''} ${params.row.first_name || ''}`;

  const columns: GridColDef[] = [
    {
      field: 'username',
      headerName: '氏名',
      flex: 0.7,
      minWidth: 120,
      valueGetter: getFullName,
    },
    { field: 'email', headerName: 'メールアドレス', flex: 1, minWidth: 240 },
    {
      field: 'join_date',
      headerName: '入社日',
      type: 'date',
      flex: 0.6,
      minWidth: 70,
    },
    {
      field: 'detail',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      flex: 0.2,
      minWidth: 40,
      renderCell: (params) => (
        <div className={classes.iconButton}>
          <DetailButton
            onClick={() => {
              navigate(
                PagePath.UserDetail.replace(':id', String(params.row.id)),
              );
            }}
          />
        </div>
      ),
    },
    {
      field: 'timecard',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      flex: 0.2,
      minWidth: 40,
      renderCell: (params) => (
        <div className={classes.iconButton}>
          <TimecardButton
            onClick={() => {
              const year = getYear();
              const month = getMonth();
              navigate(
                PagePath.Timecard.replace(':id', String(params.row.id))
                  .replace(':year', year)
                  .replace(':month', month),
              );
            }}
          />
        </div>
      ),
    },
    {
      field: 'delete',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      flex: 0.2,
      minWidth: 40,
      renderCell: (params) => {
        const userName = getFullName(params);
        return (
          <div className={classes.iconButton}>
            <DeleteDialog
              userName={userName}
              disabled={!(roleType === RoleTypeAdmin)}
              userId={String(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const errorContent = useMemo(() => {
    if (errorStatus && errorStatus in ErrorStatusMessage) {
      return ErrorStatusMessage[errorStatus];
    }
    return 'エラーが発生しました。時間をおいて再度試してください。';
  }, [errorStatus]);

  return (
    <>
      {!isError ? (
        <Container maxWidth="md" sx={{ py: 1 }}>
          <PageTitle text="ユーザー一覧" />
          <Paper>
            <div className={classes.table}>
              <DataGrid
                rows={dataGridRows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
              />
            </div>
          </Paper>
        </Container>
      ) : (
        <ErrorMessage>{errorContent}</ErrorMessage>
      )}
    </>
  );
};

export default UsersPage;
