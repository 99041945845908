import React, { useCallback, useMemo, useState, MouseEvent } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import { createStyles, makeStyles } from '@mui/styles';
import MenuButton from 'src/components/atoms/menu_button';
import AccountButton from 'src/components/atoms/account_button';
import DrawerContent from 'src/components/molecules/drawer_content';
import AccountContent from 'src/components/molecules/account_content';
import { signout } from 'src/commons/utils/firebase/auth';
import { useUserInfo } from 'src/commons/state/user';
import { RoleTypeStaff } from 'src/commons/const/role';

const useStyles = makeStyles(() =>
  createStyles({
    space: {
      flexGrow: 1,
    },
  }),
);

const AppBar: React.FC = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { info } = useUserInfo();
  const roleType = info?.role__type;

  const handleClickMenu = useCallback(
    () => setDrawerOpen(true),
    [setDrawerOpen],
  );

  const handleClickAccount = useCallback(
    (event: MouseEvent) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleCloseDrawerContent = useCallback(
    () => setDrawerOpen(false),
    [setDrawerOpen],
  );

  const handleCloseAccountContent = useCallback(
    () => setAnchorEl(null),
    [setAnchorEl],
  );

  const handleLogout = useCallback(() => {
    signout();
  }, []);

  return useMemo(
    () => (
      <>
        <MuiAppBar position="static">
          <MuiToolbar>
            {info &&
              typeof roleType === 'number' &&
              roleType !== RoleTypeStaff && (
                <MenuButton onClick={handleClickMenu} />
              )}
            <div className={classes.space} />
            <AccountButton onClick={handleClickAccount} />
            <AccountContent
              anchorEl={anchorEl}
              onClose={handleCloseAccountContent}
              onLogout={handleLogout}
            />
          </MuiToolbar>
        </MuiAppBar>
        <DrawerContent open={drawerOpen} onClose={handleCloseDrawerContent} />
      </>
    ),
    [
      classes,
      handleClickMenu,
      anchorEl,
      handleClickAccount,
      drawerOpen,
      handleCloseDrawerContent,
      handleCloseAccountContent,
      handleLogout,
      info,
      roleType,
    ],
  );
};

export default AppBar;
