import React, { useMemo } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    error: {
      width: '100%',
      spacing: 2,
    },
  }),
);

const ErrorMessage: React.FC = ({ children }) => {
  const classes = useStyles();

  return useMemo(
    () => (
      <Stack className={classes.error}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {children}
        </Alert>
      </Stack>
    ),
    [classes, children],
  );
};

export default ErrorMessage;
