import React, { useMemo } from 'react';
import MuiIconButton from '@mui/material/IconButton';
import MuiMenuIcon from '@mui/icons-material/Menu';
import { IconButtonColor } from 'src/commons/const/color';

type Props = {
  onClick?: VoidFunction;
};

const MenuButton: React.FC<Props> = ({ onClick }) =>
  useMemo(
    () => (
      <MuiIconButton
        size="large"
        edge="start"
        color={IconButtonColor}
        aria-label="menu"
        onClick={onClick}
      >
        <MuiMenuIcon />
      </MuiIconButton>
    ),
    [onClick],
  );

export default MenuButton;
