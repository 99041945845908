import { authApi } from 'src/commons/rest';

// 出勤簿を取得する
export const getTimecard = async (
  userId: string,
  year: number,
  month: number,
) => {
  const { data } = await authApi.get(
    `/attendance_record/${userId}/${year}/${month}`,
  );
  return data;
};

// 勤怠情報CSVを取得する
export const getAllAttendanceRecordCsv = async (
  startDate: string,
  endDate: string,
) => {
  const { data } = await authApi.get(
    `/all_attendance_record/${startDate}/${endDate}`,
    { responseType: 'blob' },
  );
  return data;
};

const RestTimecard = {
  getTimecard,
  getAllAttendanceRecordCsv,
};

export default RestTimecard;
