import React, { MouseEvent, useMemo } from 'react';
import MuiIconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButtonColor } from 'src/commons/const/color';

type Props = {
  onClick?: (event: MouseEvent) => void;
};

const BackButton: React.FC<Props> = ({ onClick }) =>
  useMemo(
    () => (
      <MuiIconButton size="small" onClick={onClick} color={IconButtonColor}>
        <ArrowBackIosIcon />
      </MuiIconButton>
    ),
    [onClick],
  );

export default BackButton;
