import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { DepartmentName } from 'src/commons/const/department';

const useStyles = makeStyles(() =>
  createStyles({
    titleWrapper: {
      textAlign: 'center',
    },
    title: {
      marginBlockEnd: '0',
    },
    contentsWrapper: {
      textDecoration: 'underline',
      display: 'flex',
      justifyContent: 'space-around',
    },
    yearMonth: {
      marginBlockStart: '0',
      marginBlockEnd: '0.5em',
    },
    list: {
      listStyle: 'none',
      marginBlockStart: '0',
      marginBlockEnd: '0.5em',
    },
  }),
);

const TimecardHeader: React.FC<{ yearMonth: string; fullName: string }> = ({
  yearMonth,
  fullName,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleWrapper}>
        <h2 className={classes.title}>出勤簿</h2>
      </div>
      <div className={classes.contentsWrapper}>
        <p className={classes.yearMonth}>{yearMonth}度</p>
        <ul className={classes.list}>
          <li>所属：{DepartmentName}</li>
          <li>氏名：{fullName}</li>
        </ul>
      </div>
    </>
  );
};

export default TimecardHeader;
