import { StatusCode } from 'src/commons/types/rest';

const StatusCode401: StatusCode = '401';
const StatusCode403: StatusCode = '403';
const StatusCode404: StatusCode = '404';

export const ErrorStatusMessage = {
  [StatusCode401]: '認証資格が不足しています。',
  [StatusCode403]: 'アクセス権限がありません。',
  [StatusCode404]: 'URLが存在しません。',
};

const Rest = {
  ErrorStatusMessage,
};

export default Rest;
