import React, { useMemo, useState } from 'react';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import DeleteButton from 'src/components/atoms/delete_button';
import { deleteUser } from 'src/commons/rest/user';
import Axios from 'axios';
import { ErrorStatusMessage } from 'src/commons/const/rest';
import { StatusCode } from 'src/commons/types/rest';
import ErrorMessage from 'src/components/atoms/error_message';

const DeleteDialog: React.FC<{
  userName: string;
  userId: string;
  disabled: boolean;
}> = ({ userName, userId, disabled }) => {
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<StatusCode | undefined>(
    undefined,
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteRow = async () => {
    try {
      await deleteUser(userId);
      setOpen(false);
      window.location.reload();
    } catch (error) {
      setIsError(true);
      const status = Axios.isAxiosError(error)
        ? error?.response?.status
        : undefined;
      const foundStatus = (
        Object.keys(ErrorStatusMessage) as (keyof typeof ErrorStatusMessage)[]
      ).find((key) => key === `${status}`);
      setErrorStatus(foundStatus);
    }
  };

  const dialogMessage = () => {
    const name =
      userName === ' ' ? `ユーザーID：${userId}` : `ユーザー：${userName}さん`;
    return `${name}を本当に削除しますか？`;
  };

  const errorContent = useMemo(() => {
    if (errorStatus && errorStatus in ErrorStatusMessage) {
      return ErrorStatusMessage[errorStatus];
    }
    return 'エラーが発生しました。時間をおいて再度試してください。';
  }, [errorStatus]);

  return (
    <>
      <DeleteButton onClick={handleOpen} disabled={disabled} />
      <Dialog open={open} onClose={handleClose}>
        {isError && <ErrorMessage>{errorContent}</ErrorMessage>}
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            autoFocus
          >
            やめる
          </Button>
          <Button onClick={deleteRow} variant="outlined" color="primary">
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
