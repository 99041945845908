export const getYear = () => {
  const date = new Date();
  return String(date.getFullYear());
};

export const getMonth = () => {
  const date = new Date();
  return String(date.getMonth() + 1);
};

const Time = {
  getYear,
  getMonth,
};

export default Time;
