import React, { MouseEvent, useMemo } from 'react';
import MuiIconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { IconButtonColor } from 'src/commons/const/color';

type Props = {
  onClick?: (event: MouseEvent) => void;
};

const DetailButton: React.FC<Props> = ({ onClick }) =>
  useMemo(
    () => (
      <MuiIconButton size="small" onClick={onClick} color={IconButtonColor}>
        <SearchIcon />
      </MuiIconButton>
    ),
    [onClick],
  );

export default DetailButton;
