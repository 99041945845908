import React, { useMemo, MouseEvent } from 'react';
import MuiIconButton from '@mui/material/IconButton';
import MuiAccountCircle from '@mui/icons-material/AccountCircle';
import { IconButtonColor } from 'src/commons/const/color';

type Props = {
  onClick?: (event: MouseEvent) => void;
};

const AccountButton: React.FC<Props> = ({ onClick }) =>
  useMemo(
    () => (
      <MuiIconButton size="large" onClick={onClick} color={IconButtonColor}>
        <MuiAccountCircle />
      </MuiIconButton>
    ),
    [onClick],
  );

export default AccountButton;
