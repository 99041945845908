import React, { useMemo, useState } from 'react';
import { getAllAttendanceRecordCsv } from 'src/commons/rest/timecard';
import Axios from 'axios';
import { ErrorStatusMessage } from 'src/commons/const/rest';
import { StatusCode } from 'src/commons/types/rest';
import { Container, TextField, Button, Stack } from '@mui/material';
import ErrorMessage from 'src/components/atoms/error_message';
import PageTitle from 'src/components/atoms/page_title';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    pageTitle: {
      marginBottom: '32px',
    },
    downloadButton: {
      float: 'right',
    },
    stack: {
      marginBottom: '24px',
    },
  }),
);

const CsvExportPage: React.FC = () => {
  const classes = useStyles();

  const [isError, setIsError] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<StatusCode | undefined>(
    undefined,
  );
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [downloadable, setDownloadable] = useState<boolean>(false);

  const onChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const onChangeEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleClick = async () => {
    try {
      if (startDate != null && endDate != null) {
        const response = await getAllAttendanceRecordCsv(
          startDate.split('-').join(''),
          endDate.split('-').join(''),
        );
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `all_attendance_record_${startDate}_${endDate}.csv`,
        );
        document.body.appendChild(link);
        link.click();
        setIsError(false);
      }
    } catch (error) {
      setIsError(true);
      const status = Axios.isAxiosError(error)
        ? error?.response?.status
        : undefined;
      const foundStatus = (
        Object.keys(ErrorStatusMessage) as (keyof typeof ErrorStatusMessage)[]
      ).find((key) => key === `${status}`);
      setErrorStatus(foundStatus);
    }
  };

  const errorContent = useMemo(() => {
    if (errorStatus && errorStatus in ErrorStatusMessage) {
      return ErrorStatusMessage[errorStatus];
    }
    return 'エラーが発生しました。時間をおいて再度試してください。';
  }, [errorStatus]);

  const helperTextMessage = useMemo(() => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        setDownloadable(false);
        return '開始日を終了日以前にしてください。';
      }
      setDownloadable(true);
    }
    return 'CSV出力したい期間を選択してください。';
  }, [startDate, endDate]);

  return (
    <>
      {isError && <ErrorMessage>{errorContent}</ErrorMessage>}
      <>
        <Container maxWidth="sm" sx={{ py: 1 }}>
          <div className={classes.pageTitle}>
            <PageTitle text="勤怠情報CSV出力" />
          </div>
          <Stack
            className={classes.stack}
            component="form"
            noValidate
            spacing={3}
          >
            <TextField
              variant="outlined"
              type="date"
              label="開始日"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={onChangeStartDate}
            />

            <TextField
              variant="outlined"
              type="date"
              label="終了日"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              helperText={helperTextMessage}
              onChange={onChangeEndDate}
            />
          </Stack>
          <Button
            className={classes.downloadButton}
            variant="contained"
            color="primary"
            size="large"
            disabled={!downloadable}
            onClick={handleClick}
          >
            ダウンロード
          </Button>
        </Container>
      </>
    </>
  );
};

export default CsvExportPage;
