import React, { useMemo } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { IconButtonColor } from 'src/commons/const/color';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: IconButtonColor,
      fontSize: 16,
    },
  }),
);

type Props = {
  text: string;
};

const MonthLabel: React.FC<Props> = ({ text }) => {
  const classes = useStyles();

  return useMemo(
    () => <p className={classes.label}>{text}</p>,
    [text, classes.label],
  );
};

export default MonthLabel;
