import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import TimecardFormat from 'src/components/organisms/timecard_format';
import MonthPagination from 'src/components/molecules/month_pagination';
import PrintIcon from '@mui/icons-material/Print';

const useStyles = makeStyles(() =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      marginLeft: 16,
      marginTop: 8,
    },
    print: {
      marginLeft: 4,
    },
  }),
);

const TimecardPage: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  const handleClick = () => {
    window.open(`${location.pathname}/preview`, '', 'width=710, height=741');
  };

  return (
    <>
      <div className={classes.buttonContainer}>
        <Button onClick={handleClick}>
          <PrintIcon fontSize="small" />
          <span className={classes.print}>印刷</span>
        </Button>
        <MonthPagination />
      </div>
      <TimecardFormat />
    </>
  );
};

export default TimecardPage;
