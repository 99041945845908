import React, { useMemo } from 'react';
import MuiMenu from '@mui/material/Menu';

type Props = {
  anchorEl: Element | null;
  onClose: VoidFunction;
};

const MenuList: React.FC<Props> = ({ anchorEl, onClose, children }) =>
  useMemo(
    () => (
      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {children}
      </MuiMenu>
    ),
    [children, anchorEl, onClose],
  );

export default MenuList;
