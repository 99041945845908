import React, { useMemo } from 'react';
import MuiListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

const ListItemText: React.FC = ({ children }) =>
  useMemo(
    () => (
      <MuiListItemText
        primary={<Typography component="h2">{children}</Typography>}
      />
    ),
    [children],
  );

export default ListItemText;
