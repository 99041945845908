import React, { MouseEvent, useMemo } from 'react';
import MuiIconButton from '@mui/material/IconButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IconButtonColor } from 'src/commons/const/color';

type Props = {
  onClick?: (event: MouseEvent) => void;
};

const TimecardButton: React.FC<Props> = ({ onClick }) =>
  useMemo(
    () => (
      <MuiIconButton size="small" onClick={onClick} color={IconButtonColor}>
        <AccessTimeIcon />
      </MuiIconButton>
    ),
    [onClick],
  );

export default TimecardButton;
