import React, { useCallback } from 'react';
import { signinWithGoogle } from 'src/commons/utils/firebase/auth';
import GoogleButton from 'react-google-button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 'calc(100vh - 64px)',
    },
  }),
);

const LoginPage: React.FC = () => {
  const classes = useStyles();

  const handleClickGoogle = useCallback(async () => {
    await signinWithGoogle();
  }, []);

  return (
    <div className={classes.container}>
      <GoogleButton onClick={handleClickGoogle} />
    </div>
  );
};

export default LoginPage;
