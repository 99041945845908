import {
  getAuth,
  signOut,
  setPersistence,
  browserSessionPersistence,
  signInWithPopup,
  GoogleAuthProvider,
  UserCredential,
} from 'firebase/auth';
import { consoleError } from 'src/commons/utils/console';

export const signinWithGoogle = async (): Promise<
  UserCredential | undefined
> => {
  try {
    const auth = getAuth();
    await setPersistence(auth, browserSessionPersistence);
    const googleProvider = new GoogleAuthProvider();
    const user = await signInWithPopup(auth, googleProvider);
    return user;
  } catch (error) {
    consoleError(error);
    return undefined;
  }
};

export const signout = async (): Promise<boolean> => {
  try {
    const auth = getAuth();
    await signOut(auth);
    return true;
  } catch (error) {
    consoleError(error);
    return false;
  }
};

export const getIdToken = async (): Promise<string | undefined> => {
  try {
    const auth = getAuth();
    const idToken = await auth.currentUser?.getIdToken();
    return idToken;
  } catch (error) {
    consoleError(error);
    return undefined;
  }
};

const FirebaseAuth = {
  signinWithGoogle,
  signout,
  getIdToken,
};

export default FirebaseAuth;
