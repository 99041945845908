import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Timecard } from 'src/commons/types/timecard';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      borderCollapse: 'collapse',
      '& th': {
        fontWeight: 'normal',
        border: 'solid 1px #f0f0f0',
        padding: '10px',
        textAlign: 'center',
      },
      '& td': {
        border: 'solid 1px #f0f0f0',
        padding: '1px',
        textAlign: 'center',
      },
    },
    memo: {
      width: 150,
    },
    saturday: {
      color: '#1976d2',
    },
    sunday: {
      color: '#D21919',
    },
  }),
);

const DayOfWeek = ['月', '火', '水', '木', '金', '土', '日'];

const TimecardTable: React.FC<{ timecardRows: Timecard[] }> = ({
  timecardRows,
}) => {
  const classes = useStyles();

  const getDayOfWeek = (day: number) => {
    if (day === 5) {
      return <span className={classes.saturday}>{DayOfWeek[day]}</span>;
    }

    if (day === 6) {
      return <span className={classes.sunday}>{DayOfWeek[day]}</span>;
    }
    return DayOfWeek[day];
  };

  return (
    <>
      <div>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>日付</th>
              <th>曜日</th>
              <th>始業時間</th>
              <th>終業時間</th>
              <th>休憩時間</th>
              <th>実勤時間</th>
              <th>残業時間</th>
              <th>備考</th>
            </tr>
          </thead>
          <tbody>
            {timecardRows.map((data) => (
              <tr key={data.date}>
                <td>{data.date}</td>
                <td>{getDayOfWeek(data.day_of_week)}</td>
                <td>{data.start_time}</td>
                <td>{data.end_time}</td>
                <td>{data.break_time}</td>
                <td>{data.working_time}</td>
                <td>{data.overtime_hours}</td>
                <td className={classes.memo}>&nbsp;</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TimecardTable;
