import React, { useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import { getUserDetail } from 'src/commons/rest/user';
import { User } from 'src/commons/types/user.js';
import { useParams, useNavigate } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import PageTitle from 'src/components/atoms/page_title';
import ErrorMessage from 'src/components/atoms/error_message';
import { StatusCode } from 'src/commons/types/rest';
import { ErrorStatusMessage } from 'src/commons/const/rest';
import { RoleName, RoleTypeAdmin } from 'src/commons/const/role';
import {
  Button,
  Container,
  Stack,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { PagePath } from 'src/commons/const/page';
import { userHasAccessToken, useUserInfo } from 'src/commons/state/user';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles(() =>
  createStyles({
    json: {
      whiteSpace: 'break-spaces',
    },
    paper: {
      paddingInlineStart: 20,
    },
    list: {
      width: '100%',
      macWidth: 360,
      bgcolor: 'background.paper',
    },
    listItem: {
      margin: 4,
    },
    listItemTitle: {
      float: 'left',
      width: '50%',
    },
    listItemData: {
      float: 'left',
      width: '50%',
    },
    editButton: {
      float: 'right',
    },
  }),
);

const UsersDetailPage: React.FC = () => {
  const classes = useStyles();
  const [user, setUser] = useState<User | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<StatusCode | undefined>(
    undefined,
  );
  const navigate = useNavigate();
  const params = useParams();
  const userId: string | undefined = useMemo(() => params.id, [params.id]);
  const { info } = useUserInfo();
  const roleType = info?.role__type;
  const hasAccessToken = useRecoilValue(userHasAccessToken);

  useEffect(() => {
    (async () => {
      try {
        if (hasAccessToken && userId) {
          const userDetail = await getUserDetail(userId);
          setUser(userDetail);
          setIsError(false);
        }
      } catch (error) {
        setIsError(true);
        const status = Axios.isAxiosError(error)
          ? error?.response?.status
          : undefined;
        const foundStatus = (
          Object.keys(ErrorStatusMessage) as (keyof typeof ErrorStatusMessage)[]
        ).find((key) => key === `${status}`);
        setErrorStatus(foundStatus);
      }
    })();
  }, [userId, info, hasAccessToken]);

  const errorContent = useMemo(() => {
    if (errorStatus && errorStatus in ErrorStatusMessage) {
      return ErrorStatusMessage[errorStatus];
    }
    return 'エラーが発生しました。時間をおいて再度試してください。';
  }, [errorStatus]);

  return (
    <>
      {!isError && (
        <Container maxWidth="md" sx={{ py: 1 }}>
          <PageTitle text="ユーザー詳細" />
          <Stack spacing={3} sx={{ pb: 2 }}>
            <Paper className={classes.paper}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    姓
                  </ListItemText>
                  <ListItemText className={classes.listItemData}>
                    {user?.last_name}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    せい
                  </ListItemText>
                  <ListItemText className={classes.listItemData}>
                    {user?.last_name_kana}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    名
                  </ListItemText>
                  <ListItemText className={classes.listItemData}>
                    {user?.first_name}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    めい
                  </ListItemText>
                  <ListItemText className={classes.listItemData}>
                    {user?.first_name_kana}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    メールアドレス
                  </ListItemText>
                  <ListItemText className={classes.listItemData}>
                    {user?.email}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    生年月日
                  </ListItemText>
                  <ListItemText className={classes.listItemData}>
                    {user?.birthday}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    住所
                  </ListItemText>
                  <ListItemText className={classes.listItemData}>
                    {user?.address}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    電話番号
                  </ListItemText>
                  <ListItemText className={classes.listItemData}>
                    {user?.tel_number}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    Slack ID
                  </ListItemText>
                  <ListItemText className={classes.listItemData}>
                    {user?.slack_id}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem className={classes.listItem}>
                  <ListItemText className={classes.listItemTitle}>
                    入社日
                  </ListItemText>
                  <ListItemText className={classes.listItemData}>
                    {user?.join_date}
                  </ListItemText>
                </ListItem>
                {roleType === RoleTypeAdmin && (
                  <>
                    <Divider />
                    <ListItem className={classes.listItem}>
                      <ListItemText className={classes.listItemTitle}>
                        権限
                      </ListItemText>
                      <ListItemText className={classes.listItemData}>
                        {user?.role__type && RoleName[user.role__type]}
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.listItem}>
                      <ListItemText className={classes.listItemTitle}>
                        被保険者整理番号
                      </ListItemText>
                      <ListItemText className={classes.listItemData}>
                        {user?.employee_id}
                      </ListItemText>
                    </ListItem>
                  </>
                )}
              </List>
            </Paper>
          </Stack>
          {(roleType === RoleTypeAdmin || userId === String(info?.id)) && (
            <Button
              className={classes.editButton}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                navigate(PagePath.UserDetailEdit.replace(':id', userId!));
              }}
            >
              <EditIcon fontSize="small" />
              編集
            </Button>
          )}
        </Container>
      )}
      {isError && <ErrorMessage>{errorContent}</ErrorMessage>}
    </>
  );
};

export default UsersDetailPage;
