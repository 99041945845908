import { RoleType } from 'src/commons/types/role_type.js';

export const RoleTypeAdmin: RoleType = 1;
export const RoleTypeReadOnly: RoleType = 2;
export const RoleTypeStaff: RoleType = 3;

export const RoleName = {
  [RoleTypeAdmin]: '管理者',
  [RoleTypeReadOnly]: '閲覧のみ',
  [RoleTypeStaff]: '社員',
};

const Role = {
  RoleName,
  RoleTypeAdmin,
  RoleTypeReadOnly,
  RoleTypeStaff,
};

export default Role;
