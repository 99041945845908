import axios, { AxiosRequestConfig } from 'axios';
import { ENV_API_BASE_URL } from 'src/commons/const/env';
import { getIdToken } from 'src/commons/utils/firebase/auth';

const config: AxiosRequestConfig = {
  baseURL: ENV_API_BASE_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const api = axios.create(config);

export const authApi = axios.create(config);

authApi.interceptors.request.use(async (value) => {
  if (typeof value.headers !== 'undefined') {
    const idToken = await getIdToken();
    // eslint-disable-next-line no-param-reassign
    value.headers.Authorization = `Bearer ${idToken || ''}`;
  }
  return value;
});

const Rest = {
  api,
  authApi,
};

export default Rest;
