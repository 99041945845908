import { atom, useRecoilState } from 'recoil';
import { useCallback } from 'react';
import { UserInfoKey } from 'src/commons/const/session_storage';
import { User } from 'src/commons/types/user.js';

export const userHasAccessToken = atom<boolean>({
  key: 'userHasAccessToken',
  default: false,
});

export const userInfo = atom<User | null>({
  key: 'userInfo',
  default: null,
});

/**
 * ログインしているユーザー情報のカスタムフック
 */

export const useUserInfo = () => {
  const [info, setInfo] = useRecoilState(userInfo);

  const handleSetInfo = useCallback(
    (value: User | null) => {
      if (value) {
        sessionStorage.setItem(UserInfoKey, JSON.stringify(value));
        setInfo(value);
      } else {
        sessionStorage.removeItem(UserInfoKey);
        setInfo(null);
      }
    },
    [setInfo],
  );

  return {
    info,
    setInfo: handleSetInfo,
  };
};

const UserState = {
  useUserInfo,
};

export default UserState;
