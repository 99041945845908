import React, { forwardRef, useMemo } from 'react';
import MuiListItem from '@mui/material/ListItem';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

type Props = {
  to: string;
};

const ListLink: React.FC<Props> = ({ to, children }) => {
  const location = useLocation();
  const selected = useMemo(
    () => location.pathname.startsWith(to),
    [location, to],
  );

  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>((props, ref) => (
        <NavLink to={to} {...props} ref={ref} />
      )),
    [to],
  );
  return useMemo(
    () => (
      <MuiListItem button selected={selected} component={renderLink}>
        {children}
      </MuiListItem>
    ),
    [selected, renderLink, children],
  );
};

export default ListLink;
