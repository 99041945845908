import React, { MouseEvent, useMemo } from 'react';
import MuiIconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButtonColor } from 'src/commons/const/color';

type Props = {
  onClick?: (event: MouseEvent) => void;
  disabled?: boolean;
};

const ForwardButton: React.FC<Props> = ({ onClick, disabled }) =>
  useMemo(
    () => (
      <MuiIconButton
        size="small"
        onClick={onClick}
        color={IconButtonColor}
        disabled={disabled}
      >
        <ArrowForwardIosIcon />
      </MuiIconButton>
    ),
    [onClick, disabled],
  );

export default ForwardButton;
