import React, { useMemo, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import { PagePath } from 'src/commons/const/page';
import { getMonth, getYear } from 'src/commons/const/utils/time';
import BackButton from 'src/components/atoms/back_button';
import ForwardButton from 'src/components/atoms/forward_button';
import MonthLabel from 'src/components/atoms/month_label';

const useStyles = makeStyles(() =>
  createStyles({
    pagination: {
      display: 'flex',
      marginLeft: 16,
      color: '#1976d2',
    },
  }),
);

const MonthPagination: React.FC = () => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const userId: string | undefined = useMemo(() => params.id, [params.id]);
  const year: number = useMemo(() => Number(params.year), [params.year]);
  const month: number = useMemo(() => Number(params.month), [params.month]);
  const currentYear: number = Number(getYear());
  const currentMonth: number = Number(getMonth());
  const [forwardDisabled, setForwardDisabled] = useState<boolean>(
    year === currentYear && month === currentMonth,
  );

  const toLastMonth = useCallback(() => {
    const backYear = month === 1 ? year - 1 : year;
    const backMonth = month === 1 ? 12 : month - 1;
    if (userId) {
      navigate(
        PagePath.Timecard.replace(':id', userId)
          .replace(':year', String(backYear))
          .replace(':month', String(backMonth)),
      );
    }
    if (forwardDisabled) {
      setForwardDisabled(false);
    }
  }, [navigate, userId, year, month, forwardDisabled]);

  const toNextMonth = useCallback(() => {
    const forwardYear = month === 12 ? year + 1 : year;
    const forwardMonth = month === 12 ? 1 : month + 1;
    navigate(
      PagePath.Timecard.replace(':id', String(userId))
        .replace(':year', String(forwardYear))
        .replace(':month', String(forwardMonth)),
    );
    if (forwardYear === currentYear && forwardMonth === currentMonth) {
      setForwardDisabled(true);
    }
  }, [navigate, userId, year, month, currentYear, currentMonth]);

  const yearMonth = useMemo(
    () => `${String(year)}年${String(month)}月`,
    [year, month],
  );

  return useMemo(
    () => (
      <>
        <div className={classes.pagination}>
          <BackButton onClick={toLastMonth} />
          <MonthLabel text={yearMonth} />
          <ForwardButton onClick={toNextMonth} disabled={forwardDisabled} />
        </div>
      </>
    ),
    [classes.pagination, toLastMonth, toNextMonth, yearMonth, forwardDisabled],
  );
};

export default MonthPagination;
