import React, { useEffect, useMemo } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { PagePath } from 'src/commons/const/page';
import AppBar from 'src/components/organisms/app_bar';
import LoginPage from 'src/pages/login';
import HomePage from 'src/pages/home';
import UsersPage from 'src/pages/users';
import UsersDetailPage from 'src/pages/users/detail';
import UsersDetailEditPage from 'src/pages/users/detail/edit';
import TimecardPage from 'src/pages/timecard';
import TimecardPreviewPage from 'src/pages/timecard/preview';
import CsvExportPage from 'src/pages/csv_export';
import NotFoundPage from 'src/pages/not_found';
import { useUserInfo, userInfo } from 'src/commons/state/user';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import AppProvider from 'src/AppProvider';
import { RoleTypeStaff } from 'src/commons/const/role';
import { UserInfoKey } from 'src/commons/const/session_storage';

const LoginGuard: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { info } = useUserInfo();

  useEffect(() => {
    if (!info) {
      navigate(PagePath.Login);
    }
  }, [info, navigate]);

  return <>{children}</>;
};

const LogoutGuard: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { info } = useUserInfo();

  // slackで初めてコマンドを打ったとき、クエリのslackIdを取得
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const slackId = query.get('slack-id');

  useEffect(() => {
    if (info != null) {
      if (slackId) {
        navigate(PagePath.UserDetailEdit.replace(':id', String(info.id)), {
          state: slackId,
        });
      }
      // 権限が社員の時は自分の詳細ページ、管理者と閲覧のみの場合はユーザー一覧へ
      else if (info.role__type === RoleTypeStaff) {
        navigate(PagePath.UserDetail.replace(':id', String(info.id)));
      } else {
        navigate(PagePath.Users);
      }
    }
  }, [navigate, info, slackId]);

  return <>{children}</>;
};

const AdminGuard: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { info } = useUserInfo();

  useEffect(() => {
    if (info != null) {
      if (info.role__type === RoleTypeStaff) {
        navigate(PagePath.UserDetail.replace(':id', String(info.id)));
      }
    }
  }, [info, navigate]);

  return <>{children}</>;
};

const initializeState = async (mutableSnapshot: MutableSnapshot) => {
  // ユーザー情報を初期値で設定する
  const info = sessionStorage.getItem(UserInfoKey);
  if (info) {
    mutableSnapshot.set(userInfo, JSON.parse(info));
  }
};

const ShowAppbar = () => {
  const location = useLocation();
  const isShow = useMemo(
    () =>
      !(
        location.pathname.startsWith('/timecard') &&
        location.pathname.endsWith('preview')
      ),
    [location.pathname],
  );

  return <>{isShow && <AppBar />}</>;
};

const App: React.FC = () => (
  <RecoilRoot initializeState={initializeState}>
    <AppProvider>
      <BrowserRouter>
        <ShowAppbar />
        <Routes>
          <Route
            path={PagePath.Login}
            element={
              <LogoutGuard>
                <LoginPage />
              </LogoutGuard>
            }
          />
          <Route
            path={PagePath.Home}
            element={
              <LoginGuard>
                <HomePage />
              </LoginGuard>
            }
          />
          <Route
            path={PagePath.Users}
            element={
              <LoginGuard>
                <AdminGuard>
                  <UsersPage />
                </AdminGuard>
              </LoginGuard>
            }
          />
          <Route
            path={PagePath.UserDetail}
            element={
              <LoginGuard>
                <UsersDetailPage />
              </LoginGuard>
            }
          />
          <Route
            path={PagePath.UserDetailEdit}
            element={
              <LoginGuard>
                <UsersDetailEditPage />
              </LoginGuard>
            }
          />
          <Route
            path={PagePath.Timecard}
            element={
              <LoginGuard>
                <TimecardPage />
              </LoginGuard>
            }
          />
          <Route
            path={PagePath.TimecardPreview}
            element={
              <LoginGuard>
                <TimecardPreviewPage />
              </LoginGuard>
            }
          />
          <Route
            path={PagePath.CsvExport}
            element={
              <LoginGuard>
                <AdminGuard>
                  <CsvExportPage />
                </AdminGuard>
              </LoginGuard>
            }
          />
          <Route path={PagePath.NotFound} element={<NotFoundPage />} />
          <Route path="/*" element={<Navigate to={PagePath.NotFound} />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  </RecoilRoot>
);

export default App;
